const React = require('react');
const $ = require('jquery');

/**
 * Renders a file uploader button. Handles direct upload to S3.
 */
class FileUploader extends React.Component {
  state = {
    errors: {
      fileSizeTooBig: 'The file that you are trying to upload is too large. Max file size is 10MB.'
    },
    file: {},
    uploadProgress: 0,
    uploadDone: false
  };

  handleFileInputChanged = (e) => {
    if (e.target.files[0].size <= 10485760) {
      this.setState({ file: e.target.files[0] }, () => {
        this.getPresignedPost();
      });
    } else {
      this.setState({ error: true, errorMessageName: 'fileSizeTooBig' })
    }
  }

  getPresignedPost = () => {
    $.ajax({
      url: this.props.presignedPostUrl,
      type: 'GET',
      dataType: 'JSON',
      data: { file_name: this.state.file.name },
      success: presignedPost => {
        this.uploadToAmazonS3(presignedPost);
      },
      error: () => {
        window.flash('error', 'There was an error while uploading your files. Please reload the page and try again.');
      }
    });
  }

  onUploadProgressChanged = (progress) => {
    this.setState({
      uploadProgress: progress
    });
  }

  uploadToAmazonS3 = (presignedPost) => {
    const { file } = this.state;
    let formData = new FormData();

    Object.keys(presignedPost.fields).forEach(key => {
      formData.append(key, presignedPost.fields[key]);
    });

    // This needs to be added last or else the request doesn't work
    formData.append('file', file);

    $.ajax({
      url: presignedPost.url,
      type: 'POST',
      dataType: 'XML',
      data: formData,
      contentType: false,
      processData: false,
      xhr: () => {
        let xhr = new window.XMLHttpRequest();

        //Upload progress
        xhr.upload.addEventListener('progress', (evt) => {
          this.onUploadProgressChanged(evt.loaded / evt.total * 100)
        }, false);

        return xhr;
      },
      success: data => {
        this.setState({uploadDone: true});
        const awsKey = data.getElementsByTagName('Key')[0].firstChild.nodeValue;
        this.setState({ awsKey });
      },
      error: () => {
        window.flash('error', 'There was an error while uploading your files. Please reload the page and try again.');
      }
    });
  }

  render = () => {
    const { awsKey, uploadProgress, uploadDone } = this.state;

    return (
      <div className='file-uploader'>
        <input id='file' name='file' type="file" onChange={this.handleFileInputChanged} />
        <input id={'awsKey'} name={'awsKey'} type='hidden' value={awsKey} />
        <span>
          { !uploadDone ? uploadProgress + "%" : 'Uploaded' }
        </span>
      </div>
    );
  }
};

export default FileUploader;